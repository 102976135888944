/**
 * Custom Component - Building Statistics
 * @author Charles Harwood
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.content.building-stats {
  font-size: 1.6em;
}

.building-stats__sibling__image {
  width: 100%;
}

@media (min-width: 1200px) {
  .building-stats__sibling {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .building-stats__sibling__image {
    display: none;
  }
}

@media (max-width: 1199px) {
  .building-stats__sibling {
    background: 0 !important;
  }
}

.building-stats .statistic-item .scWebEditInput {
  left: 50% !important;
  font-weight: normal !important;
}

.building-stats .field-statstitle:after {
  display: block;
  width: 1.89474em;
  height: 0.05263em;
  margin-top: 0.92105em;
  margin-bottom: 0.39474em;
  background-color: #ee2737;
  content: '';
}

.building-stats .statistic-item {
  position: relative;
  padding: 1.25em 0em 1.25em 45%;
  border-bottom: 1px solid #eaebeb;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1em;
}

.building-stats .statistic-item:last-child {
  border-bottom: 0;
}

.building-stats .statistic-item > span {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 40%;
  max-width: 27.5rem;
  transform: translateY(-50%);
  font-weight: bold;
}

.building-stats .statistic-item .star-rating {
  max-width: calc(17px * 6);
  height: 16px;
  background-image: url("../images/icons/icon-star.svg");
  background-repeat: repeat-x;
  background-position: left center;
  background-size: 17px 16px;
}

.building-stats .statistic-item .star-rating.width-0 {
  background: none;
  content: '0';
}

.building-stats .statistic-item .star-rating.width-05 {
  width: calc(17px * 0.5);
}

.building-stats .statistic-item .star-rating.width-10 {
  width: calc(17px * 1);
}

.building-stats .statistic-item .star-rating.width-15 {
  width: calc(17px * 1.5);
}

.building-stats .statistic-item .star-rating.width-20 {
  width: calc(17px * 2);
}

.building-stats .statistic-item .star-rating.width-25 {
  width: calc(17px * 2.5);
}

.building-stats .statistic-item .star-rating.width-30 {
  width: calc(17px * 3);
}

.building-stats .statistic-item .star-rating.width-35 {
  width: calc(17px * 3.5);
}

.building-stats .statistic-item .star-rating.width-40 {
  width: calc(17px * 4);
}

.building-stats .statistic-item .star-rating.width-45 {
  width: calc(17px * 4.5);
}

.building-stats .statistic-item .star-rating.width-50 {
  width: calc(17px * 5);
}

.building-stats .statistic-item .star-rating.width-55 {
  width: calc(17px * 5.5);
}

.building-stats .statistic-item .star-rating.width-60 {
  width: calc(17px * 6);
}

@media (min-width: 1200px) {
  .building-stats .statistic-item {
    padding-left: 17.1875em;
  }
}

@media (min-width: 1630px) {
  .building-stats {
    max-width: 66rem !important;
    margin-right: auto;
  }
}
